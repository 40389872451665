
import { computed,ref } from 'vue';
import router from './router';
// let isFrist = computed(()=>{
//   return router.currentRoute.value.fullPath == '/';
// })
export default {
  computed: { },
  data(){
    return{

    }
  }
}
