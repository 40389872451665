import store from '@/store'
import { el } from 'element-plus/es/locale'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // meta: {gorouter: true},
    component: HomeView
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('../views/InviteView.vue')
  },
  {
    path: '/myinvite',
    name: 'myinvite',
    component: () => import('../views/InviterView.vue')
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('../views/meView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/Withdrawal.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/History.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(to.meta.gorouter){
//     if(localStorage.getItem("erp.token")){
//       next()
//     }else{
//       next({
//         path: '/login',
//         query: {redirect: to.fullPath}
//       })
//     }
//   }else{
//     next()
//   }
// })

export default router
